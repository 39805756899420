import service from '@/utils/request';

// @Tags RmMenuParam
// @Summary 创建RmMenuParam
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmMenuParam true "创建RmMenuParam"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmMenuParam/createRmMenuParam [post]
export var createRmMenuParam = function createRmMenuParam(data) {
  return service({
    url: "/rmMenuParam/createRmMenuParam",
    method: 'post',
    data: data
  });
};

// @Tags RmMenuParam
// @Summary 删除RmMenuParam
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmMenuParam true "删除RmMenuParam"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmMenuParam/deleteRmMenuParam [delete]
export var deleteRmMenuParam = function deleteRmMenuParam(data) {
  return service({
    url: "/rmMenuParam/deleteRmMenuParam",
    method: 'delete',
    data: data
  });
};

// @Tags RmMenuParam
// @Summary 删除RmMenuParam
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除RmMenuParam"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmMenuParam/deleteRmMenuParam [delete]
export var deleteRmMenuParamByIds = function deleteRmMenuParamByIds(data) {
  return service({
    url: "/rmMenuParam/deleteRmMenuParamByIds",
    method: 'delete',
    data: data
  });
};

// @Tags RmMenuParam
// @Summary 更新RmMenuParam
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmMenuParam true "更新RmMenuParam"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /rmMenuParam/updateRmMenuParam [put]
export var updateRmMenuParam = function updateRmMenuParam(data) {
  return service({
    url: "/rmMenuParam/updateRmMenuParam",
    method: 'put',
    data: data
  });
};

// @Tags RmMenuParam
// @Summary 用id查询RmMenuParam
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmMenuParam true "用id查询RmMenuParam"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /rmMenuParam/findRmMenuParam [get]
export var findRmMenuParam = function findRmMenuParam(params) {
  return service({
    url: "/rmMenuParam/findRmMenuParam",
    method: 'get',
    params: params
  });
};

// @Tags RmMenuParam
// @Summary 分页获取RmMenuParam列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取RmMenuParam列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmMenuParam/getRmMenuParamList [get]
export var getRmMenuParamList = function getRmMenuParamList(params) {
  return service({
    url: "/rmMenuParam/getRmMenuParamList",
    method: 'get',
    params: params
  });
};